<script>
  import EventBus from "@/eventbus"
  import api from "@/api"
  import moment from "moment"

  export default {
    name: "questionnaire-key",
    data: function() {
      return {
        pageState: null,
        startSecondsRemaining: null,
        secondsRemaining: null,
        code: null,
      }
    },
    computed: {
      barProgress() {
        return ((this.startSecondsRemaining - this.secondsRemaining) / this.startSecondsRemaining)*100
      }
    },
    created() {
      this.$store.dispatch("resetQuestionnaireState")
      this.$store.dispatch("pushPage", this.$route.name)
      this.requestKey()
    },
    methods: {
      requestKey() {       
        this.pageState = "loading" 
        this.withErrors = false

        var params = {
          "key": this.$route.query.key, 
          "data": this.$route.query.data
        }
        api.getQuestionnaireKeyCode(params).then((response) => {
          if (response.status == 200) {
            this.pageState = "keyReady" 
            this.code = response.data.code
            this.startSecondsRemaining = moment.duration(moment(response.data.expires).diff(moment())).asSeconds()
            this.secondsRemaining = this.startSecondsRemaining
            var id = setInterval((() => {
              if (this.secondsRemaining <= 0) {
                clearInterval(id)
                this.pageState = "keyExpired" 
                this.code = null
              }
              this.secondsRemaining-= 1

            }).bind(this), 1000)
          } else {
            this.$router.replace({name: "home"})
            EventBus.$emit("errorMessage", "Impossibile reperire il codice temporaneo")
          }
        }).catch(() => {
          this.$router.replace({name: "home"})
          EventBus.$emit("errorMessage", "Impossibile reperire il codice temporaneo") 
        })
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <div class="box is-flex is-flex-direction-column is-align-items-center section" v-if="pageState == 'loading'">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("loadingTitle") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("loadingSubtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>

          <div v-if="pageState == 'keyReady'">
            <b-progress :value="barProgress"></b-progress>
            <div class="box is-flex is-flex-direction-column is-align-items-center section has-background-success-light">
              <div class="block has-text-centered">
                
                <h2 class="subtitle is-5 has-text-success-dark">
                  {{ $t("loadedKeyTitle") }}
                </h2>
                <h1 class="title is-2 has-text-success-dark">
                  {{ code }}
                </h1>
              </div>
            </div>
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message1") }}
              </div>
            </div>
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message2") }}
              </div>
            </div>
          </div>

          <div v-if="pageState == 'keyExpired'">
            <div class="box is-flex is-flex-direction-column is-align-items-center section has-background-danger-light">
              <div class="block has-text-centered">
                <h1 class="title is-3 has-text-danger-dark">
                  {{ $t("expiredKeyTitle") }}
                </h1>
                <h2 class="subtitle is-5 has-text-danger-dark">
                  {{ $t("expiredKeySubtitle") }}
                </h2>
              </div>
            </div>
            <button class="button is-fullwidth is-medium" v-on:click="requestKey()">{{ $t("newCodeButton") }}</button>
          </div>
        </div>
      </div>  
    </div>
  </div> 
</template>

<i18n>
{
  "it": {
    "loadingTitle": "Generazione codice temporaneo",
    "loadingSubtitle": "L'operazione dovrebbe richiedere qualche secondo",
    "loadedKeyTitle": "Codice temporaneo",
    "message1": "Comunica questo codice al personale della struttura all’accesso",
    "message2": "Il codice è valido per 30 secondi",
    "expiredKeyTitle": "Tempo scaduto",
    "expiredKeySubtitle": "Il codice temporaneo è stato disabilitato",
    "newCodeButton": "Genera nuovo codice"
  }
}
</i18n>

<style>
</style>